.auth-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;

    .form-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        height: 75vh;
        width: 20vw;

        h1 {
            color: #707070;
            font-weight: lighter;
            margin-bottom: 2em;
        }

        img {
            margin-bottom: 2em;
        }

        a {
            font-size: 1vw;
            color: #006840;
        }

        form {
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;

            input {
                margin: 1em 0;
                width: 90%;
                height: 3vw;
                border-radius: 1vw;
                border-style: none;
                background-color: #f7f7f7;
            }

            a {
                margin: 2em 0;
            }

            button {
                margin: 2em 0;
                width: 90%;
                height: 4vw;
                border-radius: 5vw;
                border-style: none;
                background-color: #006840;
                color: white;
                font-size: 1.5vw;
            }
        }
    }
}
