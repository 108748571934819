.navbar-wrapper {
    width: 100vw;

    .navbar-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            max-width: 75px;
        }

        .links {
            width: 40%;
            display: flex;
            justify-content: space-evenly;

            .link {
                a {
                    color: #707070;
                    text-decoration: none;
                    margin: 17px;
                }
            }
        }
    }
}
