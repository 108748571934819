$spacer: 1rem;

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

//h1 {
//    font-size: 3rem;
//}
//
//h2 {
//    font-size: 2.75rem;
//}
//
//h3 {
//    font-size: 2.5rem;
//}
//
//h4 {
//    font-size: 2.25rem;
//}
//
//h5 {
//    font-size: 2rem;
//}
//
//h6 {
//    font-size: 1.75rem;
//}
//
//p {
//    font-size: 1.5rem;
//}

.weight-normal {
    font-weight: 400;
}

/*
 * `Color`s
 */

.vm-green {
    color: #006840;
}

.white {
    color: white;
}

.black {
    color: black;
}

.bg-vm-green {
    background-color: #006840 !important;
}

.bg-gray {
    background-color: rgba(141, 141, 141, 0.22);
}

/*
 * Positioning
 */

.flex {
    display: flex;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-start {
    justify-content: flex-start;
}

.wrap {
    flex-wrap: wrap;
}

.text-center {
    text-align: center;
}

/*
 * Font Styling
 */

.text-decoration-none {
    text-decoration: none;
}

.font-weight-normal {
    font-weight: normal;
}

/*
 * Display Styling
 */

.d-inline {
    display: inline;
}

.btn {
    background-color: #006840;
    color: white;
    padding: 10px 20px;
    border-radius: 15px;
    border: none;
}

@layer utilities {
    @variants responsive {
        /* Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

.grow {
    flex-grow: 1;
}

.grow-0 {
    flex-grow: 0;
}

.vimax-icon {
    display: inline-block;
    user-select: none;
    vertical-align: text-bottom;
}
